<template>
  <div class="">
    <center-title title="发票详情" :bottom="15"></center-title>
    <div class="detail-list lighter xs">
      <div class="detail-item mb20">
        <div class="item-title bg-center">订单信息</div>
        <div class="item-ul">
          <div class="item-li row">
            <div class="li-title">订单状态</div>
            <div class="li-content">已完成</div>
          </div>
          <div class="item-li row">
            <div class="li-title">发票申请时间</div>
            <div class="li-content">
              {{
                moment(billDetail.create_time * 1000).format("YYYY-MM-DD HH:mm")
              }}
            </div>
          </div>
          <div class="item-li row">
            <div class="li-title">发票类型</div>
            <div class="li-content">
              {{ billDetail.type == 1 ? "普通发票" : "增值税发票" }}
            </div>
          </div>
          <div class="item-li row">
            <div class="li-title">发票状态</div>
            <div class="li-content primary">
              {{ billDetail.status ? "已开票" : "等待开票" }}
            </div>
          </div>
          <!-- <div class="item-li row">
            <div class="li-title">订单内容</div>
            <div class="li-content row arrow">
              内含 10 个订单
              <router-link to="" class="link warn"
                >查看明细<img src="@/assets/icon/icon-arrow8.png" alt=""
              /></router-link>
            </div>
          </div> -->
        </div>
      </div>
      <div class="detail-item mb20">
        <div class="item-title bg-center">发票信息</div>
        <div class="item-ul">
          <div class="item-li row">
            <div class="li-title">抬头类型</div>
            <div class="li-content">
              {{ billDetail.is_company == 0 ? "个人" : "企业单位" }}
            </div>
          </div>
          <div class="item-li row">
            <div class="li-title">发票内容</div>
            <div class="li-content">{{ billDetail.bill_content }}</div>
          </div>
          <div class="item-li row">
            <div class="li-title">
              {{ billDetail.is_company ? "公司名称" : "个人名称" }}
            </div>
            <div class="li-content">{{ billDetail.company_name }}</div>
          </div>
          <div class="item-li row" v-if="billDetail.is_company">
            <div class="li-title">公司税号</div>
            <div class="li-content row">{{ billDetail.tax_sn }}</div>
          </div>
          <div class="item-li row">
            <div class="li-title">发票备注</div>
            <div class="li-content">
              {{ billDetail.remarks ? billDetail.remarks : "无" }}
            </div>
          </div>
          <div class="item-li row">
            <div class="li-title">查看附件</div>
            <div class="li-content">
                <span
                  class="cursor primary"
                  v-if="billDetail.bill_img"
                  @click="toUrl"
                >
                  <span v-if="isGeneratingInvoice">正在生成发票...</span>
                  <span v-else>查看/下载发票附件</span>
                </span>
                <span v-else>无</span>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-item">
        <div class="item-title bg-center">收票信息</div>
        <div class="item-ul">
          <div class="item-li row">
            <div class="li-title">收票人</div>
            <div class="li-content">{{ billDetail.contact }}</div>
          </div>
          <div class="item-li row">
            <div class="li-title">联系电话</div>
            <div class="li-content">{{ billDetail.telephone }}</div>
          </div>
          <!-- <div class="item-li row">
            <div class="li-title">下单时间</div>
            <div class="li-content">
              {{ moment(detailInfo.create_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}
            </div>
          </div> -->
          <div class="item-li row">
            <div class="li-title">收票地址</div>
            <div class="li-content row">
              {{ billDetail.province }} - {{ billDetail.city }} -
              {{ billDetail.district }}
            </div>
          </div>
          <div class="item-li row">
            <div class="li-title">详细地址</div>
            <div class="li-content row">{{ billDetail.address }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      moment,
      billDetail: {},
      isGeneratingInvoice: false, // 控制文本显示
    };
  },
  computed: {},
  watch: {},
  methods: {
    toUrl() {
      if (this.billDetail.company_id == 57) {
        this.isGeneratingInvoice = true; // 改变文本内容
        setTimeout(() => {
          window.open(this.billDetail.bill_img, "_blank");
          this.isGeneratingInvoice = false; // 重置文本内容
        }, 2000);
      } else {
        window.open(this.billDetail.bill_img, "_blank");
      }
    },
    getBillDetail() {
      this.$api
        .getBillDetailApi({
          bill_id: this.$route.query.id,
        })
        .then((res) => {
          this.billDetail = res.data;
        });
    },
  },
  created() {
    // this.detailInfo = JSON.parse(decodeURIComponent(this.$route.query.data));
    this.getBillDetail();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.detail-list {
  margin-bottom: 30px;

  .detail-item {
    .item-title {
      color: $-color-normal;
      font-size: 16px;
      font-weight: 500;
    }

    .item-li,
    .item-title {
      height: 32px;
      line-height: 32px;
      padding-left: 16px;
    }

    .item-ul {
      border-left: $-solid-border;
      border-right: $-solid-border;

      .item-li {
        border-bottom: $-solid-border;

        .li-title {
          width: 160px;
          border-right: $-solid-border;
        }

        .li-content {
          padding-left: 20px;

          &.arrow {
            .link {
              margin-left: 40px;
            }

            img {
              width: 12px;
              height: 12px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
